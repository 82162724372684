import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
var setPopperWidth = {
  enabled: true,
  fn: function fn(data) {
    // eslint-disable-next-line no-param-reassign
    data.state.styles.popper.width = "".concat(data.state.rects.reference.width, "px");
  },
  name: 'setPopperWidth',
  phase: 'write'
};
export function getModifiers(props) {
  var modifiers = [{
    enabled: !!props.flip,
    name: 'flip'
  }, {
    name: 'preventOverflow',
    options: {
      mainAxis: false
    }
  }];
  if (props.align !== 'right' && props.align !== 'left') {
    modifiers.push(setPopperWidth);
  }
  return modifiers;
}
export function getPlacement(props) {
  var x = props.align === 'right' ? 'end' : 'start';
  var y = props.dropup ? 'top' : 'bottom';
  return "".concat(y, "-").concat(x);
}
export function useOverlay(referenceElement, options) {
  var _useState = useState(null),
    _useState2 = _slicedToArray(_useState, 2),
    popperElement = _useState2[0],
    attachRef = _useState2[1];
  var _usePopper = usePopper(referenceElement, popperElement, {
      modifiers: getModifiers(options),
      placement: getPlacement(options),
      strategy: options.positionFixed ? 'fixed' : 'absolute'
    }),
    attributes = _usePopper.attributes,
    styles = _usePopper.styles,
    forceUpdate = _usePopper.forceUpdate;
  var refElementHeight = referenceElement === null || referenceElement === void 0 ? void 0 : referenceElement.offsetHeight;

  // Re-position the popper if the height of the reference element changes.
  // Exclude `forceUpdate` from dependencies since it changes with each render.
  useEffect(function () {
    forceUpdate && forceUpdate();
  }, [refElementHeight]); // eslint-disable-line

  return _objectSpread(_objectSpread({}, attributes.popper), {}, {
    innerRef: attachRef,
    style: styles.popper
  });
}
export default useOverlay;