import getMatchBounds from './getMatchBounds';
import getOptionLabel from './getOptionLabel';
import hasOwnProperty from './hasOwnProperty';
import { isString } from './nodash';
function getHintText(_ref) {
  var activeIndex = _ref.activeIndex,
    initialItem = _ref.initialItem,
    isFocused = _ref.isFocused,
    isMenuShown = _ref.isMenuShown,
    labelKey = _ref.labelKey,
    multiple = _ref.multiple,
    selected = _ref.selected,
    text = _ref.text;
  // Don't display a hint under the following conditions:
  if (
  // No text entered.
  !text ||
  // The input is not focused.
  !isFocused ||
  // The menu is hidden.
  !isMenuShown ||
  // No item in the menu.
  !initialItem ||
  // The initial item is a custom option.
  !isString(initialItem) && hasOwnProperty(initialItem, 'customOption') ||
  // The initial item is disabled
  !isString(initialItem) && initialItem.disabled ||
  // One of the menu items is active.
  activeIndex > -1 ||
  // There's already a selection in single-select mode.
  !!selected.length && !multiple) {
    return '';
  }
  var initialItemStr = getOptionLabel(initialItem, labelKey);
  var bounds = getMatchBounds(initialItemStr.toLowerCase(), text.toLowerCase());
  if (!(bounds && bounds.start === 0)) {
    return '';
  }

  // Text matching is case- and accent-insensitive, so to display the hint
  // correctly, splice the input string with the hint string.
  return text + initialItemStr.slice(bounds.end, initialItemStr.length);
}
export default getHintText;